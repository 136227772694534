import React, { useContext, useState, useEffect } from "react";
import App, { AppContext } from "./App";
import axios from "axios";
import { toast } from "react-toastify";

const Login = () => {
   //===============================
  //STATE
  //===============================
    const apiUrl = process.env.REACT_APP_API_URL;
    const appContenxt = useContext(AppContext);
    const [company, setCompany] = useState("");
    const [password, setPassword] = useState("");
  //===============================
  //HOOKS
  //===============================

  //===============================
  //HANDLERS
  //===============================
    const login = async() => {
        if (company && password){
            try{
                const data = { company, password };
                const response = await axios.post(`${apiUrl}/auth`, data);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('company', company);
                appContenxt.setState(1);
            }catch(err){
                toast.error(err.response.data.message);
            }
        }else{
            toast.error("Company and password are required")
        }
        
    }

  return (
    <div>
        <div>
            <label className="formLabel font-bold">Company ID</label>
            <div className="gap10"></div>
            <input type="text"  name={'company'} onChange={(e)=> setCompany(e.target.value)}className="inputText" />
        </div>
        <div className="gap10"></div>
        <div>
            <label className="formLabel font-bold">Password</label>
            <div className="gap10"></div>
            <input type="password"  name={'password'} onChange={(e)=> setPassword(e.target.value)}className="inputText"/>
        </div>
        <div className="gap20"></div>
        <button onClick={login} className="btnCustom btnBlock btnBlack">Login</button>
    </div>
  );
}

export default Login;