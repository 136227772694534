import './App.css';
import React, { createContext, useEffect, useState, useRef } from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Record from './Record';
import Login from './Login';
import Terms from './Terms';
import Navbar from './Navbar';

export const AppContext = createContext({
  setState: (state)=>{}
});

const App = () => {
  //================================
  //STATE
  //================================
  const [loading,setLoading] = useState(true);
  const [state, setState] = useState(0);//0=login //1=terms 2=record
  const [token, setToken] = useState("")

  //================================
  //HOOKS
  //================================
  useEffect(() =>{
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    if (token){
      setToken(token);
      if(!user){
        setState(1)
      }else{
        setState(2);
      }
    }else {
      setState(0);
    }
    setLoading(false)
  },[])

  //================================
  //HANDLERS
  //================================
  const renderComponent = () => {
    if(state == 0){
      return <Login/>
    }else if (state == 1){
      return <Terms/>
    }else if (state == 2){
      return <Record/>
    }else{
      return <div></div>
    }
  }

  const contextValue = {
    setState: setState
  }

  if (loading) return <div className='center-div'>Loading ..</div>


  return (
    <AppContext.Provider value={contextValue}>
      <Navbar/>
      <div className='gap20'></div>
      <div className='center-div'>
          {renderComponent()}
          <ToastContainer bodyClassName="toastBody"/>
      </div>
      <div style={{height:100}}></div>
    </AppContext.Provider>
  );
}

export default App;
