import './App.css';
import React, { useContext, useState, useRef, useEffect } from 'react'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import Recorder from "./Recorder/index";
import AudioWaveform from './Cutter/AudioWaveform';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { AppContext } from './App';
import recordImg from './assets/images/record.png';
import stopImg from './assets/images/stop.png'

const audioContext = new (window.AudioContext || window.webkitAudioContext)({
  sampleRate: 16000,
});

const Record = () => {
  //================================
  //STATE
  //================================
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [uploading,setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [folders, setFolders] = useState([]);
  const [folder, setFolder] = useState("");
  const [type, setType] = useState("sound")
  const [gender,setGender] = useState("male")
  const [conditions, setConditions] = useState("clean")
  const [roomSize, setRoomSize] = useState("small")
  const [field, setField] = useState("near")
  const [newOption, setNewOption] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const recorderRef = useRef(null);
  const [audio, setAudio] = useState("");
  const [blob, setBlob] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0)
  const [uploaded, setUploaded] = useState({uploaded: false, filename: "", folder: "", company: ""});

  const appContenxt = useContext(AppContext);

  //================================
  //HOOKS
  //================================
  useEffect(() => {
    // Function to be called when the event is fired
    const handleBeforeUnload = async (event) => {
        // Your logic here, e.g., saving data
        console.log('Handling before unload event', isRecording);
        // Uncomment the next line to show a confirmation dialog
        if(isRecording){
          event.returnValue = true
        }

    };

    // Adding the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId.current);
      }

      // Removing the event listener
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRecording]);

  const timeoutId = useRef(null);

  const recorderControls = useAudioRecorder();
  const streamRef = useRef(null);
  useEffect(() =>{
    const fetchData = async() => {
        try{
            const token = localStorage.getItem('token');
            const company = localStorage.getItem('company');
            const audio = localStorage.getItem('audio');
            const type = localStorage.getItem('type')

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const response = await axios.get(`${apiUrl}/folders?company=${company}`,config)
            setAudio(audio)
            if (type) setType(type)
            if (response.data.folders.length > 0){
              setFolder(response.data.folders[0])
              setFolders([...response.data.folders]);
            }else{
              setFolders(["DEFAULT"])
              setFolder("DEFAULT")
            }

        }catch(err){
            if (err.response.status == 401){
                localStorage.removeItem('token')
                localStorage.removeItem('company')
                appContenxt.setState(0)
            }
            toast.error(err.response.data.message)
        }finally{
            setLoading(false);
        }
    }
    fetchData()
  },[])

  //================================
  //HANDLERS
  //================================
  const startRecording = async () => {
    const audioTrackConstraints = {
      sampleRate: 16000,
      channelCount: 1,
      volume: 1.0,
      echoCancellation: true,
      noiseSuppression: false
    };

    onDelete()
    setElapsedTime(0)

    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: audioTrackConstraints,
        video: false
      });

      const audioContext = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 16000,
      });
      await audioContext.resume();


      recorderRef.current = new Recorder(audioContext, {
        numChannels: 1,
      });

      if (recorderRef.current && streamRef.current) {
        recorderRef.current.init(streamRef.current);
        recorderRef.current.start();
        setIsRecording(true);
        if (type == 'utterance'){
          timeoutId.current = setTimeout(stopRecording, 3000)
        }
      }
    }catch(err){
      console.log(err);
    }
  };

  const stopRecording = async () => {
    if (recorderRef.current) {
      const { blob } = await recorderRef.current.stop();
      addAudioElement(blob)
      setIsRecording(false);
    }

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null; // reset the timeoutId
    }

    // Stop all tracks
    if(streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null; // Reset the stream reference
    }
  };

  const onDelete = () =>{
    setAudio("");
    setUploaded({uploaded: false})
    localStorage.removeItem('audio')
    localStorage.removeItem('type')
  }

  const onUpload = async(start,end) => {
    if(folder){
      try{
        setUploading(true)
        const company = localStorage.getItem('company');
        const user = localStorage.getItem('user');
        let filename = '';
        const randomId = Math.floor(1000 + Math.random() * 9000);
        const name = user.replace(/\s/g, '_').toLowerCase()
        if (type == 'utterance'){
          filename = `${name}_${type}_${gender}_${roomSize}_${field}_${conditions}_${randomId}.wav`
        }else{
          filename = `${name}_${type}_${randomId}.wav`
        }
        const blob = base64ToBlob(audio, 'audio/wav');
        let data = new FormData()
        data.append('file', blob)
        data.append('company', company)
        data.append('folder', folder)
        data.append('speaker', user)
        data.append('start', start)
        data.append('end', end)
        data.append('filename',filename)

        const token = localStorage.getItem('token')

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await axios.post(`${apiUrl}/upload`, data, config)
        setUploaded({uploaded: true, filename, folder, company})
        toast.success("Data succesfully uploaded")
      }catch(err){
        if (err.response.status == 401){
            localStorage.removeItem('token')
            localStorage.removeItem('company')
            appContenxt.setState(0)
        }
        toast.error(err.response.data.message)
      }finally{
        setUploading(false)
      }
    }else{
        toast.error("Folder is required");
    }
  }

  const onDeleteFile = async()=>{
    if (uploaded.uploaded){
      try{
        setDeleting(true)
        const token = localStorage.getItem('token')

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const { company, folder, filename } = uploaded
        const response = await axios.delete(`${apiUrl}/delete-file?folder=${folder}&filename=${filename}&company=${company}`,config)
        toast.success("Succefully deleted file");
        setUploaded({uploaded: false})

      }catch(err){
        if (err.response.status == 401){
          localStorage.removeItem('token')
          localStorage.removeItem('company')
          appContenxt.setState(0)
      }
        toast.error(err.response.data.message)
      }finally{
        setDeleting(false)
      }
    }
    
  }

  const addAudioElement = (blob) => {
    const wavBlob = new Blob ([blob], { type: 'audio/wav' })
    const reader = new FileReader();
    reader.readAsDataURL(wavBlob);
    reader.onloadend = function() {
        let base64data = reader.result;
        setAudio(base64data)
        localStorage.setItem("audio", base64data)
        localStorage.setItem("type", type)
    }
  };

  const base64ToBlob = (base64, mimeType) => {
    // Split the base64 string and get the content part
    let base64ContentArray = base64.split(",");
    let base64Content = base64ContentArray.length > 1 ? base64ContentArray[1] : base64ContentArray[0];

    // Decode the base64 string
    let byteCharacters = atob(base64Content);

    // Create a byte array
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Convert to a byte array
    let byteArray = new Uint8Array(byteNumbers);

    // Create a blob with the mime type
    return new Blob([byteArray], {type: mimeType});
}

const onDownload = () => {
  const user = localStorage.getItem('user');
  let filename = '';
  const randomId = Math.floor(1000 + Math.random() * 9000);
  const name = user.replace(/\s/g, '_').toLowerCase()
  if (type == 'utterance'){
    filename = `${name}_${type}_${gender}_${roomSize}_${field}_${conditions}_${randomId}.wav`
  }else{
    filename = `${name}_${type}_${randomId}.wav`
  }
  // Create an anchor element
  let anchor = document.createElement("a");

  // Set the href and download attributes
  anchor.href = audio;
  anchor.download = filename;

  // Append the anchor to the body
  document.body.appendChild(anchor);

  // Trigger the download by simulating a click
  anchor.click();

  // Remove the anchor from the document
  document.body.removeChild(anchor);
}

  const onSave = (start,end) => {
    console.log(start,end)
  }

  const handleNewOptionChange = (e) => {
    setNewOption(e.target.value);
  };

  const addOption = () => {
    if (newOption && !folders.includes(newOption)) {
      setFolders([...folders, newOption]);
      setFolder(newOption);
      setNewOption('');
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  if(loading) return <div>Loading ... </div>

  if (uploading) return <div>Uploading content. Please wait ...</div>

  if (deleting) return <div>Deleting file. Please wait ...</div>

  return (
    <div>
        {
            folders.length > 0
             &&
             <div>
             <label className="formLabel font-bold">Select Folder</label>
             <div className="gap10"></div>
             <div>
              <select 
                  className='customSelect'
                  name="folder"
                  value={folder}
                  onChange={(e)=>setFolder(e.target.value)}>
                  { folders.map((folder) => (
                      <option key={folder} value={folder}>{folder}</option>
                  ))}
              </select>
              </div>
              <div className='gap10'></div>
              <input
                  className='inputText'
                  type="text"
                  value={newOption}
                  onChange={handleNewOptionChange}
                  placeholder="Add new folder"
                />
                <div style={{textAlign:'right', marginTop:10}}>
                  <button className='btnCustom btnSmall btnBlack' onClick={addOption}>Add</button>
                </div>
                
             <div className="gap10"></div>
         </div>
        }
        
      <div>
        <label className="formLabel font-bold">Recording Type</label>
        <div className="gap10"></div>
        <select 
            className='customSelect'
            name="type"
            value={type}
            onChange={(e)=>setType(e.target.value)}>
              <option value={"sound"}>Sound</option>
              <option value={"utterance"}>Utterance</option>
        </select>
      </div>
      <div className="gap10"></div>
      {
        type == 'utterance'
          &&
        <div>
          <div>
            <label className="formLabel font-bold">Gender</label>
            <div className="gap10"></div>
            <select 
                className='customSelect'
                name="gender"
                value={gender}
                onChange={(e)=>setGender(e.target.value)}>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
            </select>
          </div>
          <div className="gap10"></div>
          <div>
            <label className="formLabel font-bold">Room Size</label>
            <div className="gap10"></div>
            <select 
                className='customSelect'
                name="roomSize"
                value={roomSize}
                onChange={(e)=>setRoomSize(e.target.value)}>
                  <option value={"small"}>Small</option>
                  <option value={"medium"}>Medium</option>
                  <option value={"small"}>Small</option>
            </select>
          </div>
          <div className="gap10"></div>
          <div>
            <label className="formLabel font-bold">Field</label>
            <div className="gap10"></div>
            <select 
                className='customSelect'
                name="field"
                value={field}
                onChange={(e)=>setField(e.target.value)}>
                  <option value={"near"}>Near</option>
                  <option value={"far"}>Far</option>
            </select>
          </div>
          <div className="gap10"></div>
          <div>
            <label className="formLabel font-bold">Conditions</label>
            <div className="gap10"></div>
            <select 
                className='customSelect'
                name="conditions"
                value={conditions}
                onChange={(e)=>setConditions(e.target.value)}>
                  <option value={"clean"}>Clean</option>
                  <option value={"noisy"}>Noisy</option>
            </select>
          </div>
        </div>
      }
      <div className="gap20"></div>
      <div>
        <div className="gap10"></div>

        <div style={{textAlign:'center'}}>
              {
                audio 
                && <div>
                    <AudioWaveform
                      type={type}
                      canDelete={uploaded.uploaded ? true : false}
                      onSave={(start,end)=>onSave(start,end)}
                      onDelete={onDeleteFile}
                      onUpload={onUpload}
                      onDownload={onDownload}
                      fileURL={audio}/>
                    </div>
              }
        </div>
        <div>
            {
              !isRecording 
                ? <div className='horizontal-center-container'>
                    <button className='recorder-button'   onClick={startRecording}>
                      <div className="circle"></div>
                    </button>
                  </div>
            
                :  <div>
                        <div className="recording-indicator">
                            <div className="recording-dot"></div>
                            <p>Recording ... {formatTime(elapsedTime)}</p>
                        </div>
                        
                        <div className='horizontal-center-container'>
                            <button className='recorder-button' onClick={stopRecording}>
                              <div className="square"></div>
                            </button>
                      </div>
                        
                    </div>
            }
        </div>


        </div>

        <div>
        </div>
    </div>
  );
}

export default Record;