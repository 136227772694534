import React from 'react';
import AonLogo from './assets/images/aonlogo.png'

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="logo">
        {/* Add your logo here, either as an img tag or any other element */}
        <img src={AonLogo} alt="Logo" />
      </div>
      {/* Add more elements here if needed */}
    </nav>
  );
};

export default Navbar;