import React, { useState, useContext } from "react";
import { AppContext } from "./App";
import axios from "axios";
import { toast } from "react-toastify";

const Terms = () => {
  //===============================
  //STATE
  //===============================
    const apiUrl = process.env.REACT_APP_API_URL;
    const [user, setUser] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const appContenxt = useContext(AppContext);
  //===============================
  //HOOKS
  //===============================

  //===============================
  //HANDLERS
  //===============================
  const onSubmit = async() => {
    if (user && isChecked){
        try{
            setSubmitting(true);
            const company = localStorage.getItem('company');
            const data = { company, user };
            const response = await axios.post(`${apiUrl}/terms`, data);
            localStorage.setItem('user', user);
            appContenxt.setState(2);
        }catch(err){
            toast.error(err.response.data.message)
        }finally {
            setSubmitting(false)
        }
    }
  }

    return (
            <div>
              <div className="modalWrapperHeader">
                <span>Data Collection</span>
              </div>
              <div className="modalWrapperBody">
                <div className="modalContWrapper">
                  <h2>Agreement</h2>
                  <p>Please read the following important information before continuing.</p>
                  <p>Please read the following Agreement. You must accept the terms of this agreement before continuing.</p>
                  <p>Please note that information provided by you in connection with using this tool, such as your voice or motion sensor recordings that we make (and may store on our servers) when you teach or use voice commands or motion sensor actions will be used by our company for non-commercial or commercial purposes, and you are giving the company a perpetual license to do so.</p>
                </div>
                <div className="gap30"></div>
                <div className="customRow">
                  <div className="customColumn-12">
                    <input onChange={(e) => setUser(e.target.value)} type="text" className="inputText" placeholder="Enter Name" />
                  </div>
                </div>
                <div className="gap30"></div>
                <div className="customRow">
                  <div className="customColumn-12 text-center">
                    <input onChange={(e) => setIsChecked(e.target.checked)} checked={isChecked} type="checkbox" id="termsAcpt" name="termsAcpt" value="" />
                    <label htmlFor="termsAcpt"> I accept the agreement</label>
                  </div>
                </div>
                <div className="gap30"></div>
                <div className="customRow">
                  <div className="customColumn-12 text-center">
                    <button onClick={onSubmit} className="btnCustom btnBlock btnBlack">Submit</button>
                  </div>
                </div>
              </div>

            </div>
    )
}

export default Terms;